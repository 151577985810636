import React, { useState, useEffect } from 'react';

import Meta from '../components/meta';

import { withPrefix } from "gatsby"

import { StaticImage } from "gatsby-plugin-image";

import { Link } from 'gatsby';

import Contents from '../components/contents';

import ReactVivus from 'react-vivus';

import InlineLogo from '../svg/inline.svg';



const Recruit = () =>{



    const outlineSvg = withPrefix('/') + 'outline.svg'

    

    const [animShow, setAnimShow] = useState(true);
    const [animStat, setAnim] = useState(false);

    useEffect(() => {

        if(animStat === true){
            document.getElementsByClassName('js-logo-outline')[0].classList.add('is-hide')
            document.getElementsByClassName('js-logo-anim')[0].classList.remove('is-hide')
        }

        return () => { setAnimShow(false) };
    },[animStat]);

    const loadLogo = () =>{
        setAnim(true)
    }



    return(
        <>

            <Meta
                title="採用情報"
                description="ニッチトップを共有するメンバー"
            />

            <Contents>

                <div className="c-sub-bg is-recruit">

                    <div className="c-sub-header is-recruit">
                        <em className="c-sub-header--eng u-josef">
                        RECRUIT
                        </em>

                        <h1 className="c-sub-header--title">
                        ニッチトップを共有するメンバー
                        </h1>
                    </div>

                </div>



                <div className="p-recruit-wrap">
                    <div className="p-recruit-nav">
                        <nav className="p-recruit-nav-list">

                            <Link to="/recruit/jobs/" title="" className="p-recruit-nav-btn is-job">
                                <div className="p-recruit-nav-btn-cnt">
                                    <p className="p-recruit-nav-btn-cnt--title">
                                    募集職種
                                    </p>

                                    <p className="p-recruit-nav-btn-cnt--text">
                                    あなたの発想で<br />
                                    しくみを創る
                                    </p>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_job.png"
                                    alt="募集職種"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                            <Link to="/recruit/culture" title="" className="p-recruit-nav-btn is-welfare">
                                <div className="p-recruit-nav-btn-cnt">
                                    <p className="p-recruit-nav-btn-cnt--title">
                                    成長支援
                                    </p>

                                    <p className="p-recruit-nav-btn-cnt--text">
                                    フレキシブルに<br />
                                    仕事を楽しむ
                                    </p>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_welfare.png"
                                    alt="成長支援"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                            <Link to="/solution" title="" className="p-recruit-nav-btn is-business is-single">
                                <div className="p-recruit-nav-btn-cnt">
                                    <div className="p-recruit-nav-btn-cnt-inner u-align-center">
                                        <p className="p-recruit-nav-btn-cnt--title">
                                        事業内容
                                        </p>

                                        <p className="p-recruit-nav-btn-cnt--text">
                                        「受発注業務を変える。」を根幹に<br />様々な事業を展開
                                        </p>
                                    </div>
                                </div>

                                <StaticImage
                                    src="../images/bg/p_recruit_nav_business.png"
                                    alt="事業内容"
                                    placeholder="blurred"
                                    quality="100"
                                    className="p-recruit-nav-btn-image"
                                />
                            </Link>

                        </nav>
                    </div>



                    <div className="p-recruit-hero">
                        <div className="p-recruit-hero-inner">

                            <Link to="/" title="" className="p-recruit-hero-box">

                                <div className="p-recruit-hero-box-inner">
                                    {/* <p className="p-recruit-hero-box--text u-align-center">
                                        アクロスソリューションズを知る
                                    </p> */}

                                    <div className="p-recruit-hero-logo-wrap">
                                        {animShow ? <ReactVivus
                                            id="logo"
                                            className="p-recruit-hero-logo is-outline js-logo-outline"
                                            option={{
                                            file:outlineSvg,
                                            duration:100,
                                            animTimingFunction: "EASE",
                                            type: "oneByOne",
                                            }}
                                            callback={
                                                loadLogo
                                            }
                                        />
                                        : null}

                                        <InlineLogo className="p-recruit-hero-logo is-hide js-logo-anim" />
                                    </div>
                                </div>

                            </Link>

                        </div>
                    </div>



                    <div className="p-recruit">

                        <div className="p-recruit-box">
                            <h2 className="p-recruit-box--title">
                            従業員ではなく、チーム
                            </h2>

                            <p className="p-recruit-box--text">
                            アクロスソリューションズは、<br />
                            一緒に働く人を従業員と呼びません。<br />
                            それぞれに得意分野がある。各々に個性や特徴がある。<br />
                            できることがあれば、できないこともある。<br />
                            できる。を集めてチームになる。<br />
                            営業企画のプロフェッショナル。<br />
                            プログラミングのプロフェッショナル。<br />
                            デザインのプロフェッショナル。<br />
                            いろんなプロフェッショナルが集まり、プロの集団になる。<br />
                            アクロスソリューションズは、従業員は募集しません。<br />
                            プロになりたい人を募集します。
                            </p>
                        </div>

                        <div className="p-recruit-entry">
                            <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                            採用について問い合わせる
                            </Link>
                        </div>

                    </div>
                </div>

            </Contents>

        </>
    )
}

export default Recruit;


